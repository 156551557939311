

<template>
  <Base title="编辑文保单位信息" titleIcon="el-icon-s-claim">
  <el-alert title="省保，市县保单位可以自行添加，国保单位请联系管理员，添加前请先查询库中是否已有单位，请勿重复添加！" type="warning" show-icon />
  <br />
  <el-form ref="refForm" :model="form" label-width="180px" :rules="rules" v-loading="isLoading">
    <el-form-item label="编号" prop="heritage_Code">
      <el-input v-model="form.heritage_Code" placeholder="编号" style="width:300px"></el-input>
      <div style="color:#aaa">
        <i class="el-icon el-icon-warning"></i> 编号根据单位情况填报，不明确，不清楚或没有，请填写 无
      </div>

    </el-form-item>
    <el-form-item label="单位名称" prop="heritage_Name">
      <el-input v-model="form.heritage_Name" placeholder="单位名称" style="width:400px"></el-input>
    </el-form-item>
    <el-form-item label="级别/机构" prop="heritageLevel_Code">
      <el-select v-model="form.heritageLevel_Code" placeholder="级别">
        <el-option v-for="item in dicHeritageLevel.filter(p => (user.inRoles(roles.prov) || p.value > 1))"
          :key="item.value" :value="item.value" :label="item.label">
        </el-option>

      </el-select>
      <div style="color:#aaa">
        <i class="el-icon el-icon-warning"></i> 添加机构请选择文博机构
      </div>
    </el-form-item>
    <el-form-item label="批次" prop="heritageBatch_ID" v-if="form.heritageLevel_Code < 4">
      <el-select v-model="form.heritageBatch_ID" placeholder="批次">
        <el-option v-for="item in dicHeritageBatch" :key="item.value" :value="parseInt(item.value)" :label="item.label">
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="类别" prop="heritageClass_ID">
      <el-select v-model="form.heritageClass_ID" placeholder="类别">
        <el-option
          v-for="item in dicHeritageClass.filter(p => form.heritageLevel_Code == '4' ? p.value > '4012' : p.value <= '4012')"
          :key="item.value" :value="parseInt(item.value)" :label="item.label">
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="时代" prop="heritageAge_ID">
      <el-select v-model="form.heritageAge_ID" placeholder="年代" clearable @clear="() => form.heritageAge_ID = null">
        <el-option v-for="item in dicHeritageAge" :key="item.value" :value="parseInt(item.value)" :label="item.label">
        </el-option>
      </el-select>
      <div style="color:#aaa">
        <i class="el-icon el-icon-warning"></i> 时代选择框内没有的，可选择近似年代，并填写时代简要介绍
      </div>
    </el-form-item>
    <el-form-item label="时代简要介绍" prop="heritageAge">
      <el-input v-model="form.heritageAge" placeholder="简要文字" style="width:300px"></el-input>
    </el-form-item>
    <el-space>
      <el-form-item label="市" prop="city_ID">
        <el-select v-model="form.city_ID" placeholder="市级" clearable>
          <el-option v-for="item in dicCity" :key="item.id" :value="item.id" :label="item.name"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="区县" prop="county_ID" label-width="80px">
        <el-select v-model="form.county_ID" placeholder="区县" clearable>
          <el-option v-for="item in dicTown" :key="item.id" :value="item.id" :label="item.name"></el-option>
        </el-select>
      </el-form-item>
    </el-space>

    <el-form-item label="详细地址" prop="heritage_Address">
      <el-input v-model="form.heritage_Address" placeholder="详细地址" type="textarea" rows="3"></el-input>
    </el-form-item>
    <el-form-item label="基本介绍" prop="summary">
      <el-input v-model="form.summary" placeholder="基本介绍" type="textarea" rows="3"></el-input>
    </el-form-item>
    <p>&nbsp;</p>
  </el-form>
  </Base>
  <div class="pageaa-footer">
    <div class="button">
      <el-button icon="el-icon-back" @click="$router.push(redirectUrl)">返回</el-button>
      <el-divider direction="vertical"></el-divider>

      <el-popconfirm title="确认保存提交吗？" @confirm="doSave()" placement="top">
        <template #reference>
          <el-button type="success" icon="el-icon-check" :loading="isLoading">
            保 存</el-button>
        </template>
      </el-popconfirm>


    </div>
  </div>
</template>

<script lang="ts">
import { useRouter, useRoute } from 'vue-router';
import Base from "../layout/Base.vue"
import { useCityRelTown } from "@/network/chinaArea";
import {
  useSelectItemList
} from "@/network/lookUp.ts";
import { useFindHeritage } from "@/network/heritageUnit.ts";
import { defineComponent, toRef, toRefs, ref, reactive, computed, watch, onMounted, inject } from "vue";
import roles from "@/types/roles.ts";
export default defineComponent({
  components: { Base },
  props: {
    id: { type: Number },
    isUnit: { type: Number }
  },
  setup(props, { emit }) {
    const route = useRoute();
    const router = useRouter();
    const refForm = ref(null);
    const user: any = inject("user");
    const redirectUrl = ref((route.query.redirect || '/HeritageUnit/create').toString());
    const rules = {


      heritage_Code: [
        { required: true, message: "请输入文保单位编号！", trigger: "blur" },
      ],
      heritage_Name: [
        { required: true, message: "请输入文保单位名称！", trigger: "blur" },
      ],
      heritageLevel_Code: [
        { required: true, message: "请输入文保单位级别！", trigger: "change" },
      ],
      heritageBatch_ID: [
        { required: true, message: "请输入文保单位批次！", trigger: "change" },
      ],
      heritageClass_ID: [
        { required: true, message: "请输入文保单位分类！", trigger: "change" },
      ],
      // heritageAge_ID: [
      //   { required: true, message: "请输入文保单位年代！", trigger: "change" },
      // ],
      city_ID: [
        { required: true, message: "请输入文保单位地址！", trigger: "change" },
      ],
      county_ID: [
        { required: true, message: "请输入文保单位地址！", trigger: "change" },
      ],
      heritage_Address: [
        { required: true, message: "请输入文保单位详细地址！", trigger: "blur" },
      ],
    };
    const form = reactive({
      id: 0,
      heritage_Code: null,
      heritage_Name: null,
      heritageLevel_Code: null,
      heritageBatch_ID: null,
      heritageClass_ID: null,
      heritageAge_ID: null,
      city_ID: null,
      county_ID: null,
      heritage_Address: null,
      summary: null,
      heritageAge: null,
      isUnit: true
    });

    const { id, isUnit } = toRefs(props);
    const [isLoading, result, find, baseSave] = useFindHeritage();
    const dicHeritageLevel = useSelectItemList("HeritageLevel");
    const dicHeritageBatch = useSelectItemList("HeritageBatch");
    const dicHeritageClass = useSelectItemList("HeritageClass");
    const dicHeritageAge = useSelectItemList("HeritageAge");


    const [dicCity, dicTown] = useCityRelTown(
      toRef(form, "city_ID"),
      toRef(form, "county_ID")
    );

    const doSave = () => {
      refForm.value.validate((valid) => {
        if (valid) {
          // const req = Object.assign({}, form);
          // req.heritageAge_ID = parseInt(req.heritageAge_ID);
          // req.heritageBatch_ID = parseInt(req.heritageBatch_ID);
          // req.heritageClass_ID = parseInt(req.heritageClass_ID);
          if (form.heritageBatch_ID == null) form.heritageBatch_ID = 3935;
          
          if (form.heritageLevel_Code == "4") {
            form.isUnit = false;
          }
          else {
            form.isUnit = true;
          }
          baseSave(form).then(p => {
            router.push(redirectUrl.value);
          });
        }
      });
    }

    onMounted(() => {
      if (id.value) {
        find(id.value).then(p => {
          Object.assign(form, p);
        });
      }
    });

    return {
      redirectUrl,
      refForm,
      doSave,
      isLoading,
      user,
      roles,
      rules,
      form,
      dicHeritageLevel,
      dicHeritageBatch,
      dicHeritageClass,
      dicHeritageAge,
      dicCity,
      dicTown
    };
  },
});
</script>

<style scoped></style>